<template>
    <div>
        <v-card v-show="showPhone && showForeignPhone" flat>
            <v-card-title class="title pb-0">{{ $t('confirmAuth.verify-phone-number') }}</v-card-title>
            <v-card-text>
                <p>{{ $t('confirmAuth.cannot-receive-SMS') }}
                <font color="Red"><b>{{ $t('confirmAuth.cannot-paste') }}</b></font></p>
                <v-form v-model="phoneValid">
                    <v-text-field outline clearable
                        v-model="phoneAuthCode"
                        prepend-icon="lock"
                        name="auth"
                        :label="$t('confirmAuth.enter-verification-code')"
                        :hint="$t('confirmAuth.six-digits')"
                        counter
                        maxlength="6"
                        type="input"
                        mask="#######"
                        :rules="phoneAuthRules"
                    ></v-text-field>
                </v-form>
                <v-layout justify-center>
                    <v-btn large outline color="green" :disabled="!phoneCanClick" @click="resend('phone')">{{ phoneContent }}</v-btn>
                </v-layout>
            </v-card-text>
        </v-card>
        <v-card v-show="showEmail" flat>
            <v-card-title class="title pb-0">{{ $t('confirmAuth.verify-email') }}</v-card-title>
            <v-card-text>
                <p>{{ $t('confirmAuth.cannot-receive-email') }}
                <font color="Red"><b>{{ $t('confirmAuth.cannot-paste') }}</b></font></p>
                <v-form v-model="emailValid">
                    <v-text-field outline clearable
                        v-model="emailAuthCode"
                        prepend-icon="lock"
                        name="auth"
                        :label="$t('confirmAuth.enter-email-verification-code')"
                        :hint="$t('confirmAuth.six-digits')"
                        counter
                        maxlength="6"
                        type="input"
                        mask="#######"
                        :rules="emailAuthRules"
                    ></v-text-field>
                </v-form>
                <v-layout justify-center>
                    <v-btn large outline color="green" :disabled="!emailCanClick" @click="resend('email')">{{ emailContent }}</v-btn>
                </v-layout>
            </v-card-text>
        </v-card>
        <v-card flat>
            <v-card-text>
                <v-layout justify-center>
                    <v-btn flat large block color="primary" @click="back">
                        <v-icon>arrow_back_ios</v-icon>{{ $t('confirmAuth.back') }}
                    </v-btn>
                    <v-btn large block color="primary" @click="submitAuth">{{ $t('confirmAuth.submit-verification') }}</v-btn>
                </v-layout>
            </v-card-text>
        </v-card>
        <waiting-dialog :show.sync="dialog.show" :msg="dialog.msg" :err-msg="dialog.err"></waiting-dialog>
        <message-dialog
            :show.sync="msgDialog.show"
            :msg="msgDialog.msg"
            :primary-btn="msgDialog.primaryBtn"
        ></message-dialog>
    </div>
</template>

<script>
import axios from 'axios'
import store from '@/store'
import WaitingDialog from '@/components/WaitingDialog.vue'
import WaitDialogControl from '@/mixins/WaitDialogControl.vue'
import MessageDialog from '@/components/MessageDialog.vue'
import MessageDialogControl from '@/mixins/MessageDialogControl.vue'
import { HostUrl } from '@/store'
import { getAxiosConfig, PWA_TOKEN} from '@/utils/AuthService'
import i18n from '@/i18n'

const AUTH_CODE_LENGTH = 6;

export default {
    name: 'confirm-auth',
    components: {
        WaitingDialog,
        MessageDialog
    },
    mixins: [WaitDialogControl, MessageDialogControl],
    props: {
        userId: {
            type: Number,
            required: true
        },
        phone: {
            type: String,
            default: null
        },
        email: {
            type: String,
            default: null
        },
        showForeignPhone : {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            phoneAuthCode: '',
            emailAuthCode: '',
            phoneAuthRules: [
                v => !!v || i18n.t('confirmAuth.enter-verification-code'),
                v => /\d{6}/.test(v) || i18n.t('confirmAuth.authRules.length-incorrect')
            ],
            emailAuthRules: [
                v => !!v || i18n.t('confirmAuth.enter-email-verification-code'),
                v => /\d{6}/.test(v) || i18n.t('confirmAuth.authRules.length-incorrect')
            ],
            // 原本authRules
            // authRules: [
            //     v => !!v || i18n.t('confirmAuth.authRules.verification-code'),
            //     v => /\d{6}/.test(v) || i18n.t('confirmAuth.authRules.length-incorrect')
            // ],
            showPhone: false,
            showEmail: false,
            phoneValid: false,
            emailValid: false,
            emailContent: i18n.t('confirmAuth.resend-email'),
            phoneContent: i18n.t('confirmAuth.resend-sms'),
            emailTime: 60,
            phoneTime: 60,
            emailCanClick: true,
            phoneCanClick: true
        }
    },
    methods: {
        back() {
            this.phoneAuthCode = ''
            this.emailAuthCode = ''
            this.$emit('back')
        },
        disablePasteCopyDrop(event) {
            event.preventDefault();
        },
        //pastephone() {
        //    let tmp = (event.clipboardData || window.clipboardData).getData("text")
        //    //console.log("paste:"+tmp)
        //    let ss = ''
        //    for (var i; i< tmp.length; i++) {
        //        if (Number.isInteger(tmp[i])){
        //            ss += tmp[i]
        //        }
        //    }
        //    if (tmp.length >=AUTH_CODE_LENGTH && ss.length >= AUTH_CODE_LENGTH) {
        //        this.phoneAuthCode = ss.substring(0,6)
        //    }
        //},
        async resend(from) {
            this.showDialog(i18n.t('confirmAuth.resend-code'), i18n.t('confirmAuth.failed-send-code'))

            if (from === 'email') {
                this.emailCanClick = false
                this.emailContent =  i18n.t('confirmAuth.resend') + `(${this.emailTime})`

                let clock = window.setInterval(() => {
                    this.emailTime--
                    this.emailContent = i18n.t('confirmAuth.resend') + `(${this.emailTime})`
                    if (this.emailTime < 0) {
                        window.clearInterval(clock)
                        this.emailContent = i18n.t('confirmAuth.resend-email')
                        this.emailTime = 60
                        this.emailCanClick = true
                    }
                }, 1000)
            }

            if (from === 'phone') {
                this.phoneCanClick = false
                this.phoneContent =  i18n.t('confirmAuth.resend') + `(${this.phoneTime})`

                let clock = window.setInterval(() => {
                    this.phoneTime--
                    this.phoneContent = i18n.t('confirmAuth.resend') + `(${this.phoneTime})`
                    if (this.phoneTime < 0) {
                        window.clearInterval(clock)
                        this.phoneContent = i18n.t('confirmAuth.resend-sms')
                        this.phoneTime = 60
                        this.phoneCanClick = true
                    }
                }, 1000)
            }
            try {
                let payload = {
                    user_id: this.userId
                }
                if (from === 'phone') {
                    payload.phone = this.phone
                }
                if (from === 'email') {
                    payload.email = this.email
                }
                let suburl = '/api/user/sendauthcode';
                let response = await axios.post(HostUrl + suburl, payload, getAxiosConfig(PWA_TOKEN))
                if (response.data.code === 0) {
                    store.commit('setSnackBar', {
                        message: i18n.t('confirmAuth.code-sent-success'),
                        color: 'success'
                    })
                } else {
                    this.showMsgDialog(response.data.message, i18n.t('confirmAuth.close'))
                }
            } catch (error) {
                this.showMsgDialog(i18n.t('confirmAuth.failed-send-code'), i18n.t('confirmAuth.close'))
            } finally {
                this.closeDialog()
            }
        },
        async confirmAuth(code) {
            if (this.phoneAuthCode || this.emailAuthCode) {
                this.showDialog(i18n.t('confirmAuth.resend-code'), i18n.t('confirmAuth.failed-send-code'))
                let payload = {
                    user_id: this.userId,
                    auth_code: code
                }
                if (this.phone) {
                    payload.phone = this.phone
                }
                if (this.email) {
                    payload.email = this.email
                }
                try {
                    let response = await axios.post(HostUrl + '/api/user/confirmauth', payload, getAxiosConfig(PWA_TOKEN))
                    if (response.data.code === 0) {
                        store.commit('setUserId', response.data.profile.id)
                        store.dispatch('changeUser', {
                            profile: response.data.profile,
                            token: response.data.token
                        })
                        store.commit('setSnackBar', {
                            message: i18n.t('confirmAuth.identity-completed'),
                            color: 'success'
                        })
                        this.$emit('pass')
                    } else {
                        store.commit('setUserToken', '')
                        let message = i18n.t('confirmAuth.code-error-enter-again')
                        if (response.data.message === "此email已註冊，請使用其他的email") {
                            message = response.data.message
                        } 
                        this.showMsgDialog(message, i18n.t('confirmAuth.close'))
                        this.phoneAuthCode = ''
                        this.emailAuthCode = ''
                    }
                } catch (error) {
                    this.showMsgDialog(i18n.t('confirmAuth.failed-send-code'), i18n.t('confirmAuth.close'))
                } finally {
                    this.closeDialog()
                }
            }
        },
        submitAuth() {
            if (!this.phoneAuthCode && !this.emailAuthCode) {
                this.showMsgDialog(i18n.t('confirmAuth.null-auth-code'), i18n.t('confirmAuth.close'))
            } else if ( (this.phoneAuthCode && this.phoneAuthCode.length !== AUTH_CODE_LENGTH) || (this.emailAuthCode && this.emailAuthCode.length !== AUTH_CODE_LENGTH) ) {
                this.showMsgDialog(i18n.t('confirmAuth.check-auth-code'), i18n.t('confirmAuth.close'))
            } else {
                let authCode = {}
                let mark = false
                if (this.phoneAuthCode && this.phoneAuthCode.length === AUTH_CODE_LENGTH) {
                    authCode.phone = this.phoneAuthCode
                    mark = true
                }
                if (this.emailAuthCode && this.emailAuthCode.length === AUTH_CODE_LENGTH) {
                    authCode.email = this.emailAuthCode
                    mark = true
                }
                if (mark) {
                    this.confirmAuth(authCode)
                } else {
                    this.showMsgDialog(i18n.t('confirmAuth.check-auth-code'), i18n.t('confirmAuth.close'))
                }
            }
        }
    },
    watch: {
        phone: function (val) {
            if (val) {
                this.showPhone = true
            } else {
                this.showPhone = false
            }
        },
        email: function (val) {
            if (val) {
                this.showEmail = true
            } else {
                this.showEmail = false
            }
        }
    },
    computed: {
        validate() {
            if (!this.phoneValid || !this.emailValid) {
                return false
            } else if (this.phoneAuthCode || this.emailAuthCode) {
                return true
            } else {
                return false
            }
        }
    }
}
</script>

<style scoped>
</style>
